input.StripeElement {
  display: block;
  width: 100%;
  margin: 0 0 12px;
  padding: 11px 12px;
  font-size: 1em;
  border: 0;
  outline: 0;
  color: #d2ccc1;
  background-color: rgba(105, 96, 87, 0.15);
  box-shadow: inset 0px -1px 0px #8a847b;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement:hover {
  background-color: rgba(105, 96, 87, 0.4);
}

.StripeElement--focus {
  background-color: rgba(105, 96, 87, 0.4);
  box-shadow: inset 0px -1px 0px #edbb76;
}

.StripeElement::placeholder {
  color: #a39689;
}
